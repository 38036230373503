<script setup lang="ts">
import {computed, ref} from 'vue'
import {storeToRefs} from 'pinia'
import {useI18n} from 'vue-i18n'
import {useModalStore} from '../stores/modal'
import {isNullOrUndefined} from '../utils/utils'
import RBButton from './Button.vue'

const {t} = useI18n()

const modalStore = useModalStore()
const {currentModal, hasModal} = storeToRefs(modalStore)

const isBusy = ref(false)

const submit = computed(() => currentModal.value?.submit || {})
const submitVisible = computed(() => (isNullOrUndefined(submit.value.visible) ? true : submit.value.visible))
const submitEnabled = computed(() => (isNullOrUndefined(submit.value.enabled) ? true : submit.value.enabled))
const submitLabel = computed(() => submit.value.label || t('submit'))
const submitIcon = computed(() => submit.value.icon || 'fa-solid fa-check-circle')
const submitVariant = computed(() => submit.value.variant || 'success')

const cancel = computed(() => currentModal.value?.cancel || {})
const cancelVisible = computed(() => (isNullOrUndefined(cancel.value.visible) ? true : cancel.value.visible))
const cancelLabel = computed(() => cancel.value.label || t('cancel'))
const cancelIcon = computed(() => cancel.value.icon || 'fa-solid fa-circle-xmark')
const cancelVariant = computed(() => cancel.value.variant || 'warning')

async function handleCallback(callback?: () => void | Promise<void>) {
  const currentModalId = currentModal.value?.id

  try {
    isBusy.value = true
    if (callback) {
      await callback()
    }
  } catch (error) {
    return console.error(`Handled callback with error ${error}`)
  } finally {
    isBusy.value = false
    if (currentModalId === currentModal.value?.id) {
      modalStore.clearModal()
    }
  }
}
</script>

<template>
  <div v-if="hasModal && currentModal" class="fixed left-0 top-0 w-screen h-screen overflow-y-scroll bg-black/50 z-modal flex">
    <div class="mx-auto my-auto p-6 flex flex-col gap-5 bg-white">
      <div>
        <h1 class="text-darkgrey">{{ currentModal.title }}</h1>
        <p class="text-darkgrey">{{ currentModal.message }}</p>
      </div>

      <div id="modal-dialog-content"></div>

      <div class="flex flex-col sm:flex-row gap-x-2 gap-y-1">
        <RBButton
          :class="{hidden: !submitVisible}"
          :is-disabled="isBusy || !submitEnabled || !submitVisible"
          :is-loading="isBusy"
          :label="submitLabel"
          :variant="submitVariant"
          @click="handleCallback(submit.callback)"
        >
          <i v-if="submitIcon" :class="submitIcon" class="pr-4"></i>
        </RBButton>
        <RBButton
          :class="{hidden: !cancelVisible}"
          :is-disabled="isBusy || !cancelVisible"
          :label="cancelLabel"
          :variant="cancelVariant"
          @click="handleCallback(cancel.callback)"
        >
          <i v-if="cancelIcon" :class="cancelIcon" class="pr-4"></i>
        </RBButton>
      </div>
    </div>
  </div>
</template>
